/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use '/src/scss/themes/button' as button;
@use '/src/scss/themes/card' as card;
@use '/src/scss/themes/checkbox' as checkbox;
@use '/src/scss/themes/form-field' as form-field;
@use '/src/scss/themes/radio' as radio;
@use '/src/scss/themes/select' as select;
@use '/src/scss/themes/sidenav' as sidenav;
@use '/src/scss/themes/slide-toggle' as slide-toggle;
@use '/src/scss/themes/table' as table;
@use '/src/scss/themes/tabs' as tabs;
@use '/src/scss/themes/tooltip' as tooltip;

@use '/src/app/modules/core/components/navigation/navigation.component.scss' as navigation;
@use '/src/app/modules/core/components/header/header.component.scss' as header;
@use '/src/app/modules/your-home/components/option-selections/option-selections.component.scss' as option-selections;

@use '/src/scss/breakpoints' as breakpoints;
@use '/src/scss/colors' as colors;
@use '/src/scss/typography' as typography;

@use 'material-symbols';

// Base mixin for Angular Material Themes. Needed to remove console warnings
@include mat.elevation-classes();
@include mat.app-background();

// Angular Material Theme Definition
$cp-theme: mat.m2-define-light-theme((
	color: (
		primary: colors.$color-primary,
		accent: colors.$color-accent,
		warn: colors.$color-warn
	),
	typography: mat.m2-define-typography-config($font-family: var(--font-regular)),
	density: 0,
));

// Angular Material Themes
@include mat.elevation-classes();
@include mat.app-background();
@include mat.button-theme($cp-theme);
@include mat.card-theme($cp-theme);
@include mat.checkbox-theme($cp-theme);
@include mat.icon-theme($cp-theme);
@include mat.form-field-theme($cp-theme);
@include mat.menu-theme($cp-theme);
@include mat.option-theme($cp-theme);
@include mat.radio-theme($cp-theme);
@include mat.select-theme($cp-theme);
@include mat.sidenav-theme($cp-theme);
@include mat.table-theme($cp-theme);
@include mat.tabs-theme($cp-theme);

// Custom Component Themes
@include button.theme($cp-theme);
@include card.theme($cp-theme);
@include checkbox.theme($cp-theme);
@include form-field.theme($cp-theme);
@include radio.theme($cp-theme);
@include select.theme($cp-theme);
@include sidenav.theme($cp-theme);
@include slide-toggle.theme($cp-theme);
@include table.theme($cp-theme);
@include tabs.theme($cp-theme);
@include tooltip.theme($cp-theme);

// Cdk Overlay Mixins
@include navigation.cp-overflow-menu;
@include header.cp-header-mat-menu;
@include option-selections.cp-option-selections-table;

// Brand Specific Font and Color variables. Override in BrandService
:root {
	--font-regular: 'Lineto-Brown-Pro';
	--brand-primary: #003048;
	--brand-secondary: #2A72A1;
	--brand-tertiary: #E9F1F7;
	--brand-tertiary-light: #F2F5F6;
	--brand-accent-gold: #F2C75C;
	--action-clickable: #1B75A1;
	--action-hover: #145575;
	--action-clickable-light: #F4F8FA;
	--action-focus: #0F3F57;
}

body,
html {
	// Default fallback font for undefined breakpoints
	@include typography.type-16;

	background: colors.$white;
	margin: 0rem;
	height: 100%;
}

// Custom definition for Material Symbols
.material-symbols-outlined {
	font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 32;
}

// For material mat-icons where the fill property is needed
.cp-font-fill {
	font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 32;
}

.cp-justify-text {
	text-align: justify;
}

// General Use Styles for Flex Layouts
.cp-center-content {
	display: flex;
	align-items: center;
	justify-content: center;

	&-column {
		@extend .cp-center-content;
		flex-direction: column;
	}

	&-left-align {
		@extend .cp-center-content;
		justify-content: left;
		flex-direction: row !important; // This allows the content to justify left if the direction is a column
	}

	&-baseline-align {
		@extend .cp-center-content;
		align-items: baseline;
	}
}

.cp-vertical-center-content {
	display: flex;
	align-items: center;

	&-left-align {
		@extend .cp-vertical-center-content;
		justify-content: left;
		flex-direction: row !important; // This allows the content to justify left if the direction is a column
	}
}

// A container contains rows, which contain columns. By default, there is a 2rem gap between rows.
// This is analogous to the Bootstrap structure, more or less.
// Note the child structure, you cannot implement a column class without a row class, which cannot be implemented without a container class.
.cp-flex-container {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	&-no-gap {
		@extend .cp-flex-container;
		gap: unset;
	}

	// By default, there is a 1rem gap between columns in a row.
	// Child elements are displayed left-to-right.
	.cp-flex-row {
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;
	
		&-no-gap {
			@extend .cp-flex-row;
			gap: unset;
		}
	
		// The columns fill the width of the row, equally. ex: 3 columns each take 33% of the width, 4 columns = 25%, etc.
		// By default, child elements are displayed top-to-bottom, with no gap.
		.cp-flex-col {
			display: flex;
			flex-direction: column;
			flex: 1;
		}
		
		.cp-flex-col-start {
			&:only-child {
				align-items: flex-start;
			}
		}
	}
}

.cp-flex-wrap {
	flex-wrap: wrap;
	display: flex;
}

.cp-opacity-0 {
	opacity: 0;
}

.cp-width-100 {
	width: 100%;
}

// When you want to hide an element and take away its bounds
.cp-display-none {
	display: none !important;
}

// When you want to hide an element but still keep its bounds intact
.cp-visibility-hidden {
	visibility: hidden !important;
}

// Checkbox
// Due to the way that the mat-checkbox has to be structured with mat-error, we must wrap a div around it with .cp-input-checkbox
.cp-input-checkbox {
	mat-error {
		@include typography.type-14;
		display: block;
		margin-top: 0.75rem;
	}
}

// text-card
// Depending on the experience, the text card may need to be styled differently
:not(.cp-welcome-text) {
	.cp-text-card-header {
		@include typography.type-24;
	}

	.cp-cta-card-header-text {
		@include typography.type-20;
	}
}

.cp-welcome-text {
	.cp-text-card-header {
		@include typography.type-32-light;
	}

	.cp-cta-card-header-text {
		@include typography.type-32-light;
	}
}

.cp-sr-only-aria {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.cp-info-tooltip {
	color: colors.$brand-secondary;
	cursor: pointer;

	&:focus-visible {
		outline: 3px solid colors.$action-focus;
	}
}

@media screen and (min-width: breakpoints.$desktop-min-width) {
	.cp-flex-wrap {
		width: 50rem;
		gap: 2.5rem;
	}
}

@media screen and (min-width: breakpoints.$tablet-min-width) and (max-width: breakpoints.$tablet-max-width) {
	.cp-flex-wrap {
		width: 36.375rem;
		gap: 2rem;
	}
}

@media (max-width: breakpoints.$mobile-max-width) {
	.cp-flex-container {
		.cp-flex-row {
			.cp-flex-col {
				// Added flex-wrap for cp-flex-row for all screens above
				// Applying min width on columns to stack up for mobile
				// This still might throw us off for some screen sizes, and need to be revisited when we have actual pages
				min-width: 12rem;
			}
		}

		.cp-flex-wrap {
			gap: 1.25rem;
			width: 100%;
		}
	}
}