@use "sass:map";
@use "@angular/material" as mat;

@use '/src/scss/colors.scss';

/* The use of !important throughout this file is to override inline
styles as part of the Angular Material default sidenav theme */

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	// Scoped to `cp-style-guide` component
	cp-style-guide { 
		.mat-content-hide-toggle {
			margin-right: unset !important;
			display: unset;
		}

		.mat-expansion-panel-body {
			padding: unset;
		}

		.mat-expansion-panel-content {
			border-bottom: 1px solid colors.$brand-primary;
		}
	}
}