@use "sass:map";
@use "@angular/material" as mat;

@use '/src/scss/breakpoints';
@use '/src/scss/colors';


/* The use of !important throughout this file is to override inline
styles as part of the Angular Material default checkbox theme */

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);

	.mdc-checkbox {
		/*
			This class is for intercepting hover events in the frame of the checkbox. Imagine the checkbox is divided into quadrants:

			---------
			| A | B |
			|--------  {{LABEL HERE}}
			| C | D |
			---------

			Where "A" (and "LABEL HERE") represent the bounds of the native HTML checkbox, and B/C/D are the rest of the touch target
		*/
		.mat-mdc-checkbox-touch-target {
			&:hover {
				// Same overrides as :hover below
				~.mdc-checkbox__native-control {
					&:not(:checked) {
						~.mdc-checkbox__background {
							background-color: colors.$action-clickable-light !important;
						}
					}

					&:checked:not(:focus) {
						~.mdc-checkbox__background {
							border-color: colors.$action-hover !important;
							background-color: colors.$action-hover !important;
						}
					}
				}
			}
		}

		.mdc-checkbox__native-control {
			// Override the control's look when enabled
			&:enabled {
				// Change the border color for all states
				~.mdc-checkbox__background {
					border-color: colors.$action-clickable !important;
				}

				// Unchecked
				&:not(:checked) {
					// Hover
					// This hover event is only triggered by the label and "invisible" native HTML checkbox, which only takes up a portion of the overall frame (see diagram above)
					&:hover {
						// The clickable-light color fills the box
						~.mdc-checkbox__background {
							background-color: colors.$action-clickable-light !important;
						}
					}

					// Focused
					// NOTE: Angular Material applies focus immediately after being checked/unchecked
					&:focus {
						~.mdc-checkbox__background {
							border-color: colors.$action-hover !important;
						}

						// Show a double border using clickable color
						~.mdc-checkbox__ripple {
							opacity: 1 !important;
							outline: 1px solid colors.$action-hover !important;
							outline-offset: 2px;
							background-color: colors.$action-clickable-light !important;
						}
					}
				}

				// Checked
				&:checked {
					// Add a simple white border
					~.mdc-checkbox__background {
						box-shadow: inset 0 0 0 0.20rem colors.$white !important;
						background-color: colors.$action-clickable !important;
					}

					// Hover
					// This hover event is only triggered by the label and "invisible" native HTML checkbox, which only takes up a portion of the overall frame (see diagram above)
					&:hover:not(:focus) {
						~.mdc-checkbox__background {
							box-shadow: inset 0 0 0 0.20rem colors.$white !important;
							background-color: colors.$action-hover !important;
							border-color: colors.$action-hover !important;
						}
					}

					// Focused
					// NOTE: Angular Material applies focus immediately after being checked/unchecked
					&:focus:not(:hover) {
						// Show a white/color/white border
						~.mdc-checkbox__background {
							outline: 1px solid colors.$action-hover !important;
							outline-offset: 2px;
							border-color: colors.$action-hover !important;
							background-color: colors.$action-hover !important;
						}
					}
				}
			}
		}
	}

	// Error state
	// Stylings are virtually the same as above, with colors swapped out for error color
	.cp-error {
		.mdc-checkbox {
			.mat-mdc-checkbox-touch-target {
				&:hover {
					~.mdc-checkbox__native-control {
						&:checked,
						&:checked:focus:not(:hover) {
							~.mdc-checkbox__background {
								background-color: colors.$error !important;
								border-color: colors.$error !important;
							}
						}
					}
				}
			}

			.mdc-checkbox__native-control {
				&:enabled {
					~.mdc-checkbox__background {
						border-color: colors.$error !important;
					}

					// Unchecked
					&:not(:checked) {
						// Focused
						&:focus {
							~.mdc-checkbox__background {
								border-color: colors.$error !important;
							}
		
							~.mdc-checkbox__ripple {
								opacity: 1 !important;
								outline: 1px solid colors.$error !important;
								outline-offset: 2px;
								background-color: colors.$action-clickable-light !important;
							}
						}
					}

					&:checked {
						~.mdc-checkbox__background {
							background-color: colors.$error !important;
							border-color: colors.$error !important;
						}

						&:hover {
							~.mdc-checkbox__background {
								background-color: colors.$error !important;
								border-color: colors.$error !important;
							}
						}
	
						// Focused
						&:focus:not(:hover) {
							~.mdc-checkbox__background {
								outline: 1px solid colors.$error !important;
								outline-offset: 2px;
								border-color: colors.$error !important;
								background-color: colors.$error !important;
							}
						}
					}
				}
			}
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	// Modify the default hover
	.mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
		border-radius: 0 !important;
	}

	.mdc-checkbox {
		padding: unset !important;

		// Remove pointer events from outside of the control
		.mat-mdc-checkbox-touch-target {
			top: 0 !important;
			left: 0 !important;
			transform: unset !important;
		}
		
		.mdc-checkbox__native-control {
			top: unset !important;
			right: unset !important;
			left: unset !important;
			bottom: unset !important;
			width: unset !important;
			height: unset !important;
	
			// Make the checkbox a bigger square
			~.mdc-checkbox__background {
				border-width: 1px !important;
				border-radius: 0 !important;
			}

			&:enabled~.mdc-checkbox__background {
				// Hide the tickmark
				.mdc-checkbox__checkmark {
					display: none !important;
				}
			}
		}
	
		.mdc-checkbox__background {
			top: unset !important;
			left: unset !important;
		}
	}

	@media (min-width: breakpoints.$tablet-min-width) {
		.mdc-checkbox {
			// Adjust the spacing around the control to match the new size
			flex: 0 0 1.5rem !important;
			width: 1.5rem !important;
			height: 1.5rem !important;
			margin-right: 1rem !important;
	
			// Remove pointer events from outside of the control
			.mat-mdc-checkbox-touch-target {
				height: 1.5rem !important;
				width: 1.5rem !important;
			}
	
			.mdc-checkbox__native-control {
				// Make the checkbox a bigger square
				~.mdc-checkbox__background {
					width: 1.5rem !important;
					height: 1.5rem !important;
				}
			}
		}
	}

	@media (max-width: breakpoints.$mobile-max-width) {
		.mdc-checkbox {
			// Adjust the spacing around the control to match the new size
			flex: 0 0 1.25rem !important;
			width: 1.25rem !important;
			height: 1.25rem !important;
			margin-right: 0.75rem !important;
	
			// Remove pointer events from outside of the control
			.mat-mdc-checkbox-touch-target {
				height: 1.25rem !important;
				width: 1.25rem !important;
			}
	
			.mdc-checkbox__native-control {
				// Make the checkbox a bigger square
				~.mdc-checkbox__background {
					width: 1.25rem !important;
					height: 1.25rem !important;
				}
			}
		}
	}

	@if $color-config !=null {
		@include color($theme);
	}
}