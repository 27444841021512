@use "sass:map";
@use "@angular/material" as mat;

@use '/src/scss/breakpoints';
@use '/src/scss/colors.scss';
@use '/src/scss/typography.scss';

/* The use of !important throughout this file is to override inline
styles as part of the Angular Material default select theme */

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$accent-palette: map.get($color-config, 'accent');

	// Default
	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) {
		.mdc-notched-outline {
			.mdc-notched-outline__leading,
			.mdc-notched-outline__notch,
			.mdc-notched-outline__trailing {
				border-color: colors.$gray-40 !important;

				// Animate the border-color (in/out)
				-webkit-transition: border-color 0.3s ease;    
				-moz-transition: border-color 0.3s ease;      
				-o-transition: border-color 0.3s ease;         
				transition: border-color 0.3s ease;
			}
		}
	}

	// Hover
	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover {
		.mdc-notched-outline {
			.mdc-notched-outline__leading,
			.mdc-notched-outline__notch,
			.mdc-notched-outline__trailing {
				border-color: colors.$action-hover !important;
			}
		}
	}

	// Focus
	.mdc-text-field--focused {
		.mdc-notched-outline {
			.mdc-notched-outline__leading,
			.mdc-notched-outline__notch,
			.mdc-notched-outline__trailing {
				border-color: colors.$action-focus !important;
			}
		}
	}

	// Error
	.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) {
		.mdc-notched-outline {
			.mdc-notched-outline__leading,
			.mdc-notched-outline__notch,
			.mdc-notched-outline__trailing {
				border-color: colors.$error !important;
			}
		}

		input {
			color: colors.$error !important;
		}
	}

	.cp-clickable-fg {
		color: colors.$action-clickable;
	}

	.cp-error-fg {
		color: colors.$error;
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	.mat-mdc-form-field {
		height: 3.375rem;
		width: 20rem;

		.mdc-notched-outline__leading,
		.mdc-notched-outline__trailing {
			border-radius: 0px !important;
		}
	}

	// Tooltip
	mat-tooltip-component {
		@include typography.type-12;

		transform: translateY(1.5rem);

		.mat-mdc-tooltip .mdc-tooltip__surface {
			color: colors.$white;
			background-color: colors.$black;
			opacity: 0.8;
			border-radius: 0 !important;
			padding: 1rem;
		}
	}

	// Form Field, Input, and Textarea
	// Due to the way that the mat-label has to be structured with mat-form-field, we must wrap a div around it with .cp-input
	.cp-input {
		max-width: 20rem;

		mat-label {
			@include typography.type-14;
			display: block;
			margin-bottom: 0.5rem;
		}

		mat-form-field {
			@include typography.type-16-light;

			// Align mat-error to the left
			.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
				@include typography.type-14;
				margin-top: 0.5rem;
				padding: unset;
			}

			// Hide required indicator
			.mat-mdc-form-field-required-marker {
				display: none;
			}

			mat-icon {
				font-weight: 600 !important; // This makes the symbol lighter but the weight value seems wrong, might be a bug with Angular Material
				font-size: 2rem !important;
				line-height: 0.75 !important;
				padding: 1rem !important;
			}
		}

		// Subclass for `textarea` element, since the base form-field class remains the same between input/textarea type
		&-textarea {
			@extend .cp-input;

			mat-form-field {
				height: 7.875rem !important;

				// Anchor the scrollbar to the edge of the control
				.mat-mdc-text-field-wrapper, .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
					padding: 0 !important;
				}

				// Increase the default height, pad the content, and remove ability to resize the textarea
				textarea {
					height: 7.875rem !important;
					padding: 1rem !important;
					resize: none;
				}
			}
		}
		
		@media (max-width: breakpoints.$mobile-max-width) {
			mat-form-field {
				@include typography.type-14-light;

				.mat-mdc-form-field-flex {
					height: 2.5rem !important;
				}

				.mdc-notched-outline {
					height: 2.5rem !important;
				}

				.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
					padding: 0.625rem 0;
				}
			}

			&-textarea {
				@extend .cp-input;

				mat-form-field {
					height: 5rem !important;

					.mat-mdc-form-field-flex {
						height: 5rem !important;
					}
	
					.mdc-notched-outline {
						height: 5rem !important;
					}

					// Increase the default height and pad the content
					textarea {
						height: 5rem !important;
						padding: 0.75rem !important;
					}
				}
			}
		}
	}

	@if $color-config != null {
		@include color($theme);
	}
}
