@use "sass:map";
@use "@angular/material" as mat;

@use '/src/scss/breakpoints';
@use '/src/scss/colors';
@use '/src/scss/typography';

/* The use of !important throughout this file is to override inline
styles as part of the Angular Material default tabs theme */

@mixin color($theme) {
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	// Scoped to the `cp-options-selection` component
	cp-option-selections {
		.mdc-tab__text-label {
			@include typography.type-16;
			margin-bottom: 0 !important;
			color: colors.$gray-70 !important;

			mat-icon {
				transform: scale(1.35) !important;
				margin-right: 1rem !important;
			}
		}

		.mdc-tab--active {
			.mdc-tab__text-label {
				@include typography.type-16-bold;
			}
		}

		.mdc-tab-indicator--active {
			border-radius: 2px !important;
			background-color: colors.$action-clickable !important;

			.mdc-tab__text-label {
				color: colors.$white !important;
			}
			
			.mdc-tab-indicator {
				display: none !important;
			}
		}
		
		// Tablet Styles
		@media screen and (min-width: breakpoints.$tablet-min-width) and (max-width: breakpoints.$tablet-max-width) {
			.mat-mdc-tab-header,
			.mat-mdc-tab-labels {
				height: 4.875rem !important;
			}

			.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
				width: 6rem !important;
				height: 4.875rem !important;
				min-width: 0 !important;
			}

			.mdc-tab__text-label {
				// Force the icon to stack on top of the label
				display: flex !important;
				flex-direction: column;

				mat-icon {
					margin-right: unset !important;
				}
			}

			// Keep the mat-icon + text centered between active/inactive states
			:not(.mdc-tab-indicator--active) {
				.mdc-tab__text-label {
					mat-icon {
						margin-bottom: 0.75rem !important;
					}
				}
			}

			.mdc-tab-indicator--active {
				.mdc-tab__text-label {
					mat-icon {
						margin-top: 0.35rem !important;
						margin-bottom: 0.5rem !important;
					}
				}
			}
		}

		// Mobile Styles
		@media screen and (max-width: breakpoints.$mobile-max-width) {
			.mat-mdc-tab-header,
			.mat-mdc-tab-labels {
				height: 4.25rem !important;
			}

			.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
				width: 4.5rem !important;
				height: 4.25rem !important;
				min-width: 0 !important;
			}

			.mdc-tab__text-label {
				@include typography.type-12;

				// Force the icon to stack on top of the label
				display: flex !important;
				flex-direction: column;

				mat-icon {
					margin-right: unset !important;
				}
			}
			
			.mdc-tab--active {
				.mdc-tab__text-label {
					@include typography.type-12-bold;
				}
			}
			

			// Keep the mat-icon + text centered between active/inactive states
			:not(.mdc-tab-indicator--active) {
				.mdc-tab__text-label {
					mat-icon {
						margin-bottom: 0.75rem !important;
					}
				}
			}

			.mdc-tab-indicator--active {
				.mdc-tab__text-label {
					mat-icon {
						margin-top: 0.35rem !important;
						margin-bottom: 0.5rem !important;
					}
				}
			}
		}
	}

	// Scoped to the `cp-service-requests` component
	cp-service-requests {
		// Forces the tab to only be as wide as its label
		.mdc-tab {
			padding: 0 !important;
		}
		
		// Adds a gap between tabs
		.mat-mdc-tab-labels {
			gap: 39px !important;
		}

		.mdc-tab__text-label {
			@include typography.type-16;
			margin-bottom: 0 !important;
		}

		.mdc-tab--active {
			.mdc-tab__text-label {
				@include typography.type-16-bold;
			}
		}

		.mat-mdc-tab .mdc-tab-indicator__content--underline {
			border-color: colors.$action-clickable !important;
		}
	}
}