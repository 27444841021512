@use "sass:map";
@use "@angular/material" as mat;

@use '/src/scss/colors.scss';

/* The use of !important throughout this file is to override inline
styles as part of the Angular Material default slide-toggle theme */

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);

	// Make the "button" white
	.mat-mdc-slide-toggle:not(.mat-disabled) .mdc-switch__shadow {
		background-color: colors.$white;
	}

	// Make the background of the toggle (when unchecked) gray
	.mdc-switch__track {
		background-color: colors.$gray-40;
	}

	// Make the background of the toggle (when checked) green
	.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track {
		background-color: colors.$success;
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	// Set the "button" position and size
	.mat-mdc-slide-toggle:not(.mat-disabled) .mdc-switch__shadow {
		transform: scale(0.7) !important;
		right: 0.05rem !important;
		left: -0.05rem !important;
	}

	// Set the "button" position for the "checked" state
	.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
		right: -0.05rem !important;
		left: 0.05rem !important;
	}

	// Make the track a little bigger
	.mdc-switch .mdc-switch__track {
		height: 1.1rem !important;
		border-radius: 0.55rem !important;
	}

	@if $color-config !=null {
		@include color($theme);
	}
}